import store from "@/store";
import accessTokenExpired from "@/config/token-exp";
import { router } from "@/router";
import axios from "axios";

axios.defaults.baseURL = "https://feed.asiris.com.sg/api/v1/";

export const defaultAxios = ({ withError = false } = {}) => {
	try {
		const axiosInstance = axios.create();

		if (withError) axiosInstance.interceptors.response.use(
			config => {
				try {
					store.commit("setError");
				} catch (error) {
					console.debug(error);
				}
				return config;
			},
			error => {
				try {
					const {
						response: {
							data
						}
					} = error;
					store.commit("setError", data);
				} catch (error) {
					console.debug(error);
				}

				return Promise.reject(error);
			}
		);
		return axiosInstance;
	} catch (error) {
		console.debug(error);
	}
};

const axiosRequest = ({
	token = () => store.getters["auth/accessToken"],
	refreshToken = () => store.dispatch("auth/refreshAccessToken"),
	tokenExpired = () => accessTokenExpired(),
	resetToken = () => store.commit("auth/setAccessToken"),
	fallback = () => router.push({ name: "Auth" }),
	wait = () => store.getters["axiosLock"],
	setWait = value => store.commit("setAxiosLock", value)
} = {}) => async ({ withError = true } = {}) => {
	const delay = (ms = 500) => new Promise(resolve => {
		setTimeout(resolve, ms);
	});

	let options = {
		headers: {
			Authorization: ""
		}
	};

	if (!token()) {
		fallback();
		throw "No token";
	} else if (tokenExpired()) {
		try {
			while (wait()) await delay();
			if (tokenExpired()) {
				setWait(true);
				await refreshToken();
				setWait(false);
			}
		} catch (error) {
			setWait(false);
			resetToken();
			console.debug(error);
			throw "Refresh token error";
		}
	}

	options.headers.Authorization = "Bearer " + token();

	const axiosInstance = axios.create({
		...options
	});

	axiosInstance.interceptors.response.use(
		config => {
			try {
				store.commit("setError");
			} catch (error) {
				console.debug(error);
			}
			return config;
		},
		error => {
			try {
				const {
					response: {
						data
					}
				} = error;
				if (withError) store.commit("setError", data);
			} catch (error) {
				console.debug(error);
			}

			return Promise.reject(error);
		}
	);

	return axiosInstance;
};

export const axiosInstance = axiosRequest();
