import Vue from "vue";

const currentLocale = new Vue.observable({ name: "en", data: {} });

const fetchCurrentLocaleData = async () => {
	try {
		const data = await import(`./locales/${currentLocale.name}.js`);

		currentLocale.data = data.default;
	} catch (error) {
		console.debug(error);
	}
};

fetchCurrentLocaleData();

export default (Vue) => {
	try {
		Vue.prototype.$i18n = (text) => {
			try {
				let currentText = currentLocale.data[text];

				if (currentText) {
					return currentText;
				}
				return text;
			} catch (error) {
				console.debug(error);
			}
		};
		Vue.prototype.$i18nSet = (name) => {
			try {
				currentLocale.name = name;
				fetchCurrentLocaleData();
			} catch (error) {
				console.debug(error);
			}
		};
	} catch (error) {
		console.debug(error);
	}
};
