<template>
	<div>
		<div class="relative bg-white">
			<div class="px-4 mx-auto max-w-7xl sm:px-6">
				<div
					class="flex items-center justify-between py-6 border-b-2 border-gray-100 md:justify-start md:space-x-10"
				>
					<div class="flex justify-start lg:w-0 lg:flex-1">
						<a href="/">
							<svg
								style="enable-background:new 0 0 64 64;"
								version="1.1"
								viewBox="0 0 64 64"
								xml:space="preserve"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								class="w-auto h-8 sm:h-10"
							>
								<g>
									<path
										fill="#2563eb"
										d="M19.9,50.4c-0.6-3.1-3.1-5.7-6.3-6.3c-5.6-1.1-10.5,3.8-9.4,9.4c0.6,3.1,3.1,5.7,6.3,6.3   C16.1,60.9,20.9,56.1,19.9,50.4z"
									/>
									<path
										fill="#2563eb"
										d="M4,4v10.7C29,14.7,49.3,35,49.3,60H60C60,29.1,34.9,4,4,4z"
									/>
									<path
										fill="#2563eb"
										d="M30.7,60h10.7c0-20.6-16.7-37.3-37.3-37.3v10.7C18.7,33.3,30.7,45.3,30.7,60z"
									/>
								</g>
							</svg>
						</a>
					</div>
					<div class="-my-2 -mr-2 md:hidden">
						<button
							type="button"
							class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
							aria-expanded="false"
						>
							<span class="sr-only">Open menu</span>
							<!-- Heroicon name: outline/menu -->
							<svg
								class="w-6 h-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M4 6h16M4 12h16M4 18h16"
								/>
							</svg>
						</button>
					</div>
					<nav class="hidden md:flex space-x-10">
						<router-link
							v-for="name in routes"
							:to="{ name }"
							:key="name"
							class="text-base font-medium text-gray-500 hover:text-gray-900 nav-link"
						>
							{{ $i18n(name) }}
						</router-link>
					</nav>
					<div class="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
						<span
							class="text-base font-medium text-gray-500 whitespace-nowrap hover:text-gray-900"
						>
							<template v-if="currentUserProfile">
								{{ currentUserProfile.email }}
							</template>
						</span>
						<button
							@click="signOutMethod"
							type="submit"
							class="relative flex justify-center px-4 py-2 ml-5 text-sm font-medium text-white bg-blue-600 border border-transparent group rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						>
							{{ $i18n("Sign out") }}
						</button>
					</div>
					<div class="flex-col hidden md:flex space-l-10">
						<LangSelector />
						<div>
							<router-link
								:to="{ name: 'Changelog' }"
								class="text-sm font-normal text-gray-500 hover:text-gray-900 nav-link"
							>
								{{ $i18n("Version").toLowerCase() }} {{ version }}
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { version } from "@/../package.json";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { routes } from "@/router";
import LangSelector from "@/components/LangSelector";

export default {
	name: "Nav",
	props: {
		version: {
			type: String,
			default: (() => {
				const versionPath = version.split(".");

				versionPath.pop();

				return versionPath.join(".");
			})(),
		},
	},

	components: {
		LangSelector,
	},
	data() {
		return {
			routes: [],
		};
	},
	computed: {
		...mapGetters("users", ["currentUserProfile"]),
	},
	methods: {
		...mapActions("auth", ["signOut"]),
		...mapMutations("auth", ["setAccessToken"]),
		...mapMutations("users", ["setCurrentUserProfile"]),
		async signOutMethod() {
			const { signOut } = this;
			await signOut();
		},
		fetchRoutes(currentUserRole) {
			try {
				return routes
					.filter(({ meta }) => {
						try {
							return meta.showInMenu && meta.role.includes(currentUserRole);
						} catch (error) {
							console.debug(error);
						}
					})
					.map(({ name }) => name);
			} catch (error) {
				console.debug(error);
			}
		},
	},
	created() {
		try {
			const {
				currentUserProfile: { role },
			} = this;
			this.routes = this.fetchRoutes(role);
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
<style>
.nav-link {
	color: #2c3e50;
}

.nav-link.router-link-exact-active {
	color: #059669;
}
</style>
