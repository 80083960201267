import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/auth",
		name: "Auth",
		meta: { role: "user", showInMenu: false },
		component: () => import("@/views/Auth.vue"),
	},
	{
		path: "/users/:organizationId?",
		name: "Users",
		meta: { role: ["superadmin", "admin"], showInMenu: true },
		component: () => import("@/views/Users/Users.vue"),
	},
	{
		path: "/organizations",
		name: "Organizations",
		meta: { role: ["superadmin"], showInMenu: true },
		component: () => import("@/views/Organizations/Organizations.vue"),
	},
	{
		path: "/keywords/:page?",
		props: true,
		name: "Keywords",
		meta: { role: ["superadmin", "admin", "user"], showInMenu: true },
		component: () => import("@/views/Keywords/Keywords.vue"),
	},
	{
		path: "/user/:method/:id?/:organizationId?",
		props: (route) => ({
			method: route.params.method,
			id: route.params.id,
			organizationId: route.params.organizationId
		}),
		name: "User",
		meta: { role: ["superadmin", "admin"] },
		component: () => import("@/views/Users/User.vue"),
	},
	{
		path: "/organization/:method/:id?",
		props: (route) => ({
			method: route.params.method,
			id: route.params.id,
		}),
		name: "Organization",
		meta: { role: ["superadmin"] },
		component: () => import("@/views/Organizations/Organization.vue"),
	},
	{
		path: "/feed/view/:id",
		props: (route) => ({
			id: route.params.id,
		}),
		name: "FeedView",
		meta: { role: ["superadmin", "admin", "user"] },
		component: () => import("@/views/Feeds/FeedView.vue"),
	},
	{
		path: "/feed/:method/:id?/:userId?",
		props: (route) => ({
			method: route.params.method,
			id: route.params.id,
			userId: route.params.userId
		}),
		name: "Feed",
		meta: { role: ["superadmin", "admin", "user"] },
		component: () => import("@/views/Feeds/Feed.vue"),
	},
	{
		path: "/keyword/:method/:id?",
		props: (route) => ({
			method: route.params.method,
			id: route.params.id,
		}),
		name: "Keyword",
		meta: { role: ["superadmin", "admin", "user"] },
		component: () => import("@/views/Keywords/Keyword.vue"),
	},
	{
		path: "/sources/:feedId/source/create",
		props: (route) => ({
			feedId: route.params.feedId,
		}),
		name: "SourceAdd",
		meta: { role: ["superadmin", "admin", "user"] },
		component: () => import("@/views/Sources/Source.vue"),
	},
	{
		path: "/sources/:feedId/:page?/:userId?",
		name: "Sources",
		props: (route) => ({
			feedId: route.params.feedId,
			page: route.params.page,
			userId: route.params.userId,
		}),
		meta: { role: ["superadmin", "admin", "user"] },
		component: () => import("@/views/Sources/Sources.vue"),
	},
	{
		path: "/changelog",
		name: "Changelog",
		meta: { role: ["superadmin", "admin", "user"], showInMenu: false },
		component: () => import("@/views/Changelog.vue"),
	},
	{
		path: "/:userId?/:page?",
		props: true,
		name: "Feeds",
		meta: { role: ["superadmin", "admin", "user"], showInMenu: true },
		component: () => import("@/views/Feeds/Feeds.vue"),
	},
	{
		path: "*",
		name: "NotFound",
		meta: { role: "*" },
		component: () => import("@/views/NotFound.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	try {
		if (to.name === "Auth" && store.getters["auth/accessToken"]) {
			next({ name: "Feeds" })
		} else {
			next()
		}
	} catch (error) {
		console.debug(error);				
	}
})

export { routes, router };
