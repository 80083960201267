<template>
	<div>
		<!-- This example requires Tailwind CSS v2.0+ -->
		<template v-if="error && 'message' in error && typeof error.message === 'string'">
			<div class="bg-blue-600" :key="key">
				<div class="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
					<div class="flex flex-wrap items-center justify-between">
						<div class="flex items-center flex-1 w-0">
							<span class="flex p-2 bg-blue-800 rounded-lg">
								<!-- Heroicon name: outline/speakerphone -->
								<svg
									class="w-6 h-6 text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
									/>
								</svg>
							</span>
							<p class="ml-3 font-medium text-white truncate">
								<span class="md:inline">
									{{ $i18n(error.message) }}
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-if="error && 'message' in error && error.message.length">
			<template v-for="{ constraints } in error.message">
				<template v-for="(value, key) in constraints">
					<div class="bg-blue-600" :key="key">
						<div class="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
							<div class="flex flex-wrap items-center justify-between">
								<div class="flex items-center flex-1 w-0">
									<span class="flex p-2 bg-blue-800 rounded-lg">
										<!-- Heroicon name: outline/speakerphone -->
										<svg
											class="w-6 h-6 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
											/>
										</svg>
									</span>
									<p class="ml-3 font-medium text-white truncate">
										<span class="md:inline">
											{{ $i18n(value) }}
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</template>
			</template>
		</template>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
	name: "Banner",
	computed: {
		...mapGetters(["error"]),
	},
	methods: {
		...mapMutations(["setError"]),
	},
};
</script>
